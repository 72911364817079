export function humanizeSize(size: string): string {
  switch (size) {
    case 'S':
      return 'SMALL'
    case 'M':
      return 'MEDIUM'
    case 'L':
      return 'LARGE'
    default:
      return size
  }
}
