<template>
  <div class="flex grow" data-test-id="digitalProduct">
    <!-- EDITABLE VIDEO RECORDING REQUEST -->
    <RFormGroup v-if="editable" :error="error" :hint="`${remainingCharactersCount} characters left`">
      <RTextarea
        class="whitespace-pre-wrap"
        :maxlength="maxTextLength"
        :placeholder="$t('js.digital_product.request.placeholder')"
        :value="videoRecordingRequest"
        @input="$emit('input', $event)"
      />
    </RFormGroup>

    <!-- SUBMITTED VIDEO RECORDING REQUEST - CHECKOUT OR ORDER PAGE -->
    <div v-else class="flex grow pt-1">
      <!-- WITH RECORDED VIDEO -->
      <div v-if="hasVideoRecordingUrl" class="flex grow flex-col">
        <div class="flex grow flex-col" :style="{ 'color': $color('checkoutItem.color.dark') }">
          <p class="text-small mt-1 p-3">
            <q>
              <i>{{ videoRecordingRequest }}</i>
            </q>
          </p>
          <video class="m-3 flex max-h-[30rem] grow bg-black" controls :src="videoRecordingUrl" />
        </div>
        <DownloadDigitalProduct :download-link="videoRecordingUrl" :download-title="videoRecordingTitle" />
      </div>

      <!-- REQUEST ONLY -->
      <div v-else data-test-id="requestText" :style="{ 'color': $color('checkoutItem.color.dark') }">
        <p class="text-small mt-1">
          <q>
            <i>{{ videoRecordingRequest }}</i>
          </q>
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import isEmpty from 'lodash/isEmpty'
import Vue from 'vue'

import DownloadDigitalProduct from '@/components/product/DownloadDigitalProduct.vue'
import RFormGroup from '@/components/ui/RFormGroup.vue'
import RTextarea from '@/components/ui/RTextarea.vue'
import { maxTextLength } from '@/services/ValidationService'

export default Vue.extend({
  components: {
    RTextarea,
    RFormGroup,
    DownloadDigitalProduct
  },

  props: {
    videoRecordingRequest: {
      type: String,
      default: ''
    },

    videoRecordingUrl: {
      type: String,
      default: ''
    },

    videoRecordingTitle: {
      type: String,
      default: ''
    },

    error: {
      type: String,
      default: ''
    },

    editable: Boolean
  },

  data() {
    return {
      maxTextLength
    }
  },

  computed: {
    hasVideoRecordingUrl() {
      return !isEmpty(this.videoRecordingUrl)
    },

    remainingCharactersCount(): number {
      return Math.max(0, maxTextLength - this.videoRecordingRequest.length)
    }
  }
})
</script>
