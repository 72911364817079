const LIMIT_PER_ITEM = 300

export default {
  sizeLeftInStock(size, stock, cartItems) {
    return Math.min(
      this.productVariantsLeftInStock(size, stock, cartItems),
      this.variantsLeftInStock(size, stock, cartItems),
      this.productsLeftInStock(stock, cartItems)
    )
  },

  productVariantsLeftInStock(size, stock, cartItems) {
    if (stock?.product_variant) {
      const productVariantStock = stock.product_variant.stock[size]

      // We're unable to find this size in stock
      if (productVariantStock === undefined) {
        return 0
      }

      let productVariantSum = 0
      cartItems.forEach((item) => {
        if (stock.product_variant.id === item.product_variant_id && size === item.size) {
          productVariantSum += item.quantity
        }
      })

      return productVariantStock.limit - (productVariantStock.sold + productVariantSum)
    } else {
      return LIMIT_PER_ITEM
    }
  },

  variantsLeftInStock(size, stock, cartItems) {
    if (stock?.variant) {
      const variantStock = stock.variant.stock[size]

      // We're unable to find this size in stock
      if (variantStock === undefined) {
        return 0
      }

      let variantSum = 0
      cartItems.forEach((item) => {
        if (stock.variant.id === (item.variant_id || item.variant?.id) && size === item.size) {
          variantSum += item.quantity
        }
      })

      return variantStock.limit - (variantStock.sold + variantSum)
    } else {
      return LIMIT_PER_ITEM
    }
  },

  productsLeftInStock(stock, cartItems) {
    if (stock?.product) {
      let productSum = 0
      cartItems.forEach((item) => {
        if (stock.product.products.includes(item.product_id)) {
          productSum += item.quantity
        }
      })

      return stock.product.limit - (stock.product.sold + productSum)
    } else {
      return LIMIT_PER_ITEM
    }
  },

  sizeAvailable(size, stock, cartItems) {
    if (!stock) {
      return true
    }

    return (
      this.productVariantsLeftInStock(size, stock, cartItems) > 0 &&
      this.variantsLeftInStock(size, stock, cartItems) > 0 &&
      this.productsLeftInStock(stock, cartItems) > 0
    )
  },

  variantAvailable(variant, cartItems): boolean {
    if (!variant?.stock) {
      return true
    }

    let availableSizes = Object.keys(variant.style.sizing)

    if (variant.stock?.product_variant) {
      Object.keys(variant.stock.product_variant.stock).forEach((size) => {
        let values = variant.stock.product_variant.stock[size]
        let productVariantSum = 0
        cartItems.forEach((item) => {
          if (variant.stock.product_variant.id === item.product_variant_id && size === item.size) {
            productVariantSum += item.quantity
          }
        })

        if (values.limit <= values.sold + productVariantSum && availableSizes.indexOf(size) >= 0) {
          availableSizes.splice(availableSizes.indexOf(size), 1)
        }
      })
    }

    if (variant.stock?.variant) {
      Object.keys(variant.stock.variant.stock).forEach((size) => {
        let values = variant.stock.variant.stock[size]
        let variantSum = 0
        cartItems.forEach((item) => {
          if (variant.stock.variant.id === (item.variant_id || item.variant.id) && size === item.size) {
            variantSum += item.quantity
          }
        })

        if (values.limit <= values.sold + variantSum && availableSizes.indexOf(size) >= 0) {
          availableSizes.splice(availableSizes.indexOf(size), 1)
        }
      })
    }

    if (availableSizes.length === 0) {
      return false
    }

    if (variant.stock?.product) {
      let productSum = 0
      cartItems.forEach((item) => {
        if (variant.stock.product.products.includes(item.product_id)) {
          productSum += item.quantity
        }
      })

      if (variant.stock.product.limit <= variant.stock.product.sold + productSum) {
        return false
      }
    }

    return true
  },

  productAvailable(product, cartItems): boolean {
    return Object.values(product.campaign_variants).some((cv) => {
      return this.variantAvailable(cv, cartItems)
    })
  }
}
