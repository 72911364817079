import { CartItem } from '@/types'

export default {
  validEuropeanSize(product: CartItem): boolean {
    if (!product.eu_sizes?.length) {
      return true
    }

    return product.eu_sizes.includes(product.size)
  }
}
