import { VueConstructor } from 'vue'

import { config } from '@/config'

export const featureFlags = {
  avsEnabled: config.avs_enabled,
  paypalEnabled: config.paypal_enabled,
  applePayEnabled: config.apple_pay_enabled,
  recommenderEnabled: config.recommender_enabled,
  upsellEnabled: config.upsell_enabled,
  klarnaEnabled: config.klarna_enabled
}

export default {
  install(Vue: VueConstructor) {
    Vue.prototype.$featureFlags = featureFlags
  }
}
