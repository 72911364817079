<template>
  <div class="_download_digital_product">
    <iframe ref="digitalProductDownload" hidden :src="downloadLink"></iframe>

    <RButton
      block
      class="my-2 mr-auto"
      :prefix-icon="downloadingLink ? 'spinner' : undefined"
      variant="primary"
      @click="downloadDigitalProduct()"
    >
      {{ downloadingLink ? 'Downloading' : 'Download' }}
    </RButton>
  </div>
</template>

<script lang="ts">
import axios from 'axios'
import Vue from 'vue'

export default Vue.extend({
  props: {
    downloadLink: {
      type: String,
      required: true
    },

    downloadTitle: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      downloadLoading: false
    }
  },

  methods: {
    downloadDigitalProduct() {
      this.downloadLoading = true
      let url = this.downloadLink
      axios({
        method: 'get',
        url: url,
        responseType: 'arraybuffer'
      }).then((response) => this.download(response))
    },

    download(response) {
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'video/mp4' }))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', this.downloadTitle + '-represent-connect.mp4')
      document.body.appendChild(link)
      link.click()
      this.downloadLoading = false
    }
  }
})
</script>
